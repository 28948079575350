import React from 'react'
import PropTypes from 'prop-types'
import { DialogContent } from '@mui/material'
import {
  ErrorFooterWrapper,
  ErrorHeader,
  ErrorHeaderTypography,
  ErrorIcon,
  ErrorMessageTypography,
  StyledButton,
  StyledDialog,
} from './styled'

const ReviewProductErrorModal = ({ errorText, dataTestIdPrefix, handleClose, isModalOpen }) => (
  <StyledDialog
    open={isModalOpen}
    onClose={handleClose}
    aria-labelledby="alert-dialog-error"
    aria-describedby="alert-dialog-error"
  >
    <DialogContent data-testid={`${dataTestIdPrefix}error-modal`}>
      <ErrorHeader>
        <ErrorIcon />
        <ErrorHeaderTypography>Form Submission Failed</ErrorHeaderTypography>
      </ErrorHeader>

      <ErrorMessageTypography variant="body1">
        {errorText.includes('already submitted')
          ? `You've already submitted a review\n for this product.`
          : `Something went wrong on our end. \nPlease try again.`}
      </ErrorMessageTypography>

      <ErrorFooterWrapper>
        <StyledButton
          data-testid={`${dataTestIdPrefix}error-modal-close-button`}
          onClick={handleClose}
          variant="contained"
          color="primary"
          disableElevation
          disableRipple
          fullWidth
        >
          Close
        </StyledButton>
      </ErrorFooterWrapper>
    </DialogContent>
  </StyledDialog>
)

ReviewProductErrorModal.propTypes = {
  errorText: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
  handleClose: PropTypes.func,
  isModalOpen: PropTypes.bool,
}

export default ReviewProductErrorModal
